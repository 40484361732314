.container {
  display: flex;
  height: 100vh;
  align-items: center;
  padding-bottom: 60px;
}

.wrapper {
  display: flex;
  align-items: center;
}

.text {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  border-left: 1px solid var(--color-base-black);
}

.title {
  padding-right: 10px;
}

.link {
  color: var(--color-secondary-main);
  text-decoration: underline;
}

@media (min-width: 1280px) {
  .container {
    padding-bottom: 80px;
  }
}
